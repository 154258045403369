export const URL =
  // process.env.REACT_APP_URL_ENVIRONMENT === "production"
  //   ? `${window.location.protocol}//api.atg.plus`
  //   : `https://api.atg.plus`;
  process.env.REACT_APP_URL_ENVIRONMENT === "production"
    ? `${window.location.protocol}//api.atg.plus`
    : `https://api.testme.com.tr`;

export const MERCURE_URL =
  // process.env.REACT_APP_URL_ENVIRONMENT === "production"
  //   ? "http://atg.plus:5000/.well-known/mercure"
  //   : "http://atg.plus:5000/.well-known/mercure";
  process.env.REACT_APP_URL_ENVIRONMENT === "production"
    ? "https://atg.plus/.well-known/mercure?topic=%2Fperson-approval"
    : "http://atg.plus:5000/.well-known/mercure?topic=%2Fperson-approval";
export const atgCompanyUid = "CF1A1BE";
export const mondiCompanyUid = "C971873";
